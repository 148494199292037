import React from "react";
import logoText from "../../public/logo-text.png";
import { Row, Col } from "antd";

export default class Footer extends React.Component {
  render() {
    return (
      <footer className="header-footer footer-bar">

        {process.env.PROJECT == "kron" ?
          <p style={{ color: "white", margin: "0" }}>
            © 2023 Charon Technical Group Co., Ltd All rights reserved
          </p> :
          <p style={{ color: "white", margin: "0" }}>
            © 2023 Q Tech Co., Ltd All rights reserved
          </p>}


      </footer>
    );
  }
}
