import React, { useEffect } from "react";
import Banner from "../../components/banner";
import Introduce from "../../components/introduce";
import Buy from "../../components/buy";
import DownloadApp from "../../components/download_app";
import Cookies from "js-cookie";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";

const WiceBody = (props) => {
  const { hideHandler } = props;
  const location = useLocation();

  const getUrlToken = (name, str) => {
    const reg = new RegExp(`(^|&)${name}=([^&]*)(&|$)`);
    const r = str.substr(1).match(reg);
    if (r != null) return decodeURIComponent(r[2]);
    return null;
  };

  useEffect(() => {
    const session = getUrlToken("session", location.search);
    const name = getUrlToken("name", location.search);
    const userId = getUrlToken("userId", location.search);

    if (
      session !== undefined &&
      name !== undefined &&
      userId !== undefined &&
      session !== null &&
      name !== null &&
      userId !== null
    ) {
      Cookies.set("session", session);
      let userInfo = { name: name, user_id: userId };
      Cookies.set("user-info", JSON.stringify(window.btoa(userInfo)));
      hideHandler(userInfo);
    }

    // let userInfo = Cookies.get("user-info");
    // let info = JSON.parse(window.atob(userInfo));
    // console.log(info)
    // if (userInfo === undefined || userInfo === null) {
    //   info = {};
    // } else {
    //   info = 
    // }
  }, []);
  return (
    <div>
      <Banner></Banner>
      {/* <Introduce></Introduce> */}
      <Buy></Buy>
      <DownloadApp></DownloadApp>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    hideHandler: (userInfo) => {
      dispatch({
        type: "HIDEMODAL",
        state: { userInfo },
      });
    },
  };
};

export default connect(null, mapDispatchToProps)(WiceBody);
