import React from "react";
import BannerImage from "../../public/banner_1.webp";
import { Row, Col } from "antd";
import DownloadButton from "./download_button"
// xs: 8, sm: 16, md: 24, lg: 32
const Banner = (props) => {
  const next = (e) => {
    let anchorName = "#download_app".replace("#", "");
    let anchorElement = document.getElementById(anchorName);
    if (anchorElement) {
      anchorElement.scrollIntoView();
    }
  };
  return (
    <Row
      id="banner"
      className="banner"
      justify="space-around"
      align="center"
      style={{ flexFlow: "row wrap-reverse" }}
    >
      <Col className="banner-item" xs={32} sm={32} md={6} lg={6}>
        <h1>数据加密，保护隐私保护您的上网安全</h1>
        <h3>一键获得更安全的上网保障</h3>
        <DownloadButton></DownloadButton>
      </Col>
      <Col
        className="banner-item"
        xs={32}
        sm={32}
        md={12}
        lg={9}
        xl={8}
        xxl={6}
        style={{ justifyContent: "end" }}
      >
        <img
          mode="scale"
          className="banner-img"
          src={BannerImage}
          alt={"Banner"}
        ></img>
      </Col>
    </Row>
  );
};

export default Banner;
