import React, { useState, useEffect } from "react";
import { Anchor } from "antd";
import logoText from "../../public/logo-text.png";
import pidanlogo from "../../public/logo-text_12.png"
import "antd/dist/antd.min.css";
import { MenuIcon, AccountIcon } from "./svgs";
import DropdownMenu from "./dropdown_menu";
import { connect } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import UserMenu from "./user-menu";

const { Link } = Anchor;
const Header = (props) => {
  const [showMenu, setShowMenu] = useState(false);
  const { showHandler, isLogin, userInfo } = props;
  const navigate = useNavigate();
  const location = useLocation();
  var groupFront = [
    { title: "首页", href: "#banner" },
    // { title: "产品功能", href: "#introduce" },
    { title: "价格&套餐", href: "#buy" },
    { title: "下载", href: "#download_app" },
  ];
  var groupBefore = [
    { title: "联系我们", href: "#contact-us" },
    { title: "隐私政策", href: "#privacy" },
    { title: "用户条款", href: "#user-terms" },
  ];

  const navigationBarItemHandle = (item) => {
    var pattern = /(\w+-?\w+)/,
      str = item.href;
    const href = pattern.exec(str)[0];

    navigate("/" + href);
  };

  const navigateBarItemAction = (item) => {
    if (location !== "/") {
      navigate("/");
    }
  };

  useEffect(() => {
    return () => { };
  }, [userInfo]);

  const Menu = () => {
    if (isLogin) {
      groupFront.splice(0, 0, { title: userInfo.name });
      groupBefore.push({ title: "退出登录", href: "#logout" });
    } else {
      groupFront.splice(0, 0, { title: "登录", href: "#login" });
    }
    return (
      <DropdownMenu
        groups={[groupFront, groupBefore]}
        onVision={showMenu}
        setVision={setShowMenu}
      ></DropdownMenu>
    );
  };

  return (
    <header id="header">
      <div className="header-footer header-content">
        <div className="header-logo">
          {
            process.env.PROJECT == "kron" ?
              <img src={logoText} alt="Logo" /> :
              <img src={pidanlogo} alt="Logo" />
          }
        </div>
        <div className="nav-groups">
          <nav className="header-nav">
            <ul className="nav-group">
              <Anchor affix={false} style={{ display: "flex" }}>
                {groupFront.map((item, key) => (
                  <li key={key}>
                    <div onClick={navigateBarItemAction.bind(this, item)}>
                      <Link title={item.title} href={item.href}></Link>
                    </div>
                  </li>
                ))}
              </Anchor>
            </ul>
          </nav>
          <nav className="header-nav">
            <ul className="nav-group" style={{ height: "30px" }}>
              {groupBefore.map((item, key) => (
                <li key={key}>
                  <a
                    href={item.href}
                    onClick={navigationBarItemHandle.bind(this, item)}
                  >
                    &nbsp;
                    {item.title}{" "}
                  </a>
                </li>
              ))}
            </ul>
          </nav>
          {isLogin ? (
            <span
              className="login"
              style={{ color: "white" }}
              onClick={console.log("Login")}
            >
              {userInfo.name !== undefined ? userInfo.name : "名字错误"}
              <UserMenu></UserMenu>
            </span>
          ) : (
            <span className="login" onClick={showHandler.bind(this)}>
              <a href="#" className="login">
                <AccountIcon
                  style={{ color: "white", marginRight: "8px" }}
                ></AccountIcon>
                <p> 登录 </p>
              </a>
            </span>
          )}
        </div>
        <div className="menu-icon">
          <MenuIcon
            style={{ color: showMenu ? "#FFCD31" : "#fff" }}
            onClick={() => {
              setShowMenu(!showMenu);
            }}
          ></MenuIcon>
          <Menu> </Menu>
        </div>
      </div>
    </header>
  );
};

const mapStateToProps = (state) => {
  console.log(state);
  return {
    isLogin: state.isLogin,
    userInfo: state.userInfo,
  };
};

const mapDispatchToProps = (action) => {
  return {
    showHandler: () => {
      action({
        type: "SHOWMODAL",
        modalType: "LOGIN",
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
