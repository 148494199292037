import { MacOSIcon, TvIcon, HarmonyOS } from "./svgs";
import { AppleFilled, WindowsFilled, AndroidFilled } from "@ant-design/icons";
import { useEffect, useState } from "react"
import { useUA } from 'use-ua-parser-js';
import { getDownloadLinkUrl } from "../api/api";
import { connect } from "react-redux";
import { message } from "antd";
const DownIconStyle = {}

const DownloadIcons = {
    "Mac OS": {
        icon: <MacOSIcon style={DownIconStyle}></MacOSIcon >,
        platform: "mac"
    },
    "Android": {
        icon: <AndroidFilled style={DownIconStyle}></AndroidFilled>,
        platform: "android"
    },
    "iOS": {
        icon: <AppleFilled style={DownIconStyle}></AppleFilled>,
        platform: "ios"
    },
    "Windows": {
        icon: <WindowsFilled style={DownIconStyle}></WindowsFilled>,
        platform: "windows"
    },
    "TV": {
        icon: <TvIcon style={DownIconStyle}></TvIcon>,
        platform: "androidTV"
    },
    "HarmonyOS": {
        icon: <HarmonyOS style={{ fill: "white" }}></HarmonyOS>,
        platform: "HarmonyOS"
    },
}

const DownloadButton = (props) => {
    const { setDownloadLinkHandle } = props
    const [links, setLinks] = useState([]);
    const UADetails = useUA();

    useEffect(async () => {
        //获取下载链接
        let result = await getDownloadLinkUrl()
        var harmony = {}
        Object.values(result).forEach((value, index, array) => {
            if (value.platform === "android") {
                for (const k in value) {
                    if (k == "platform") {
                        harmony.platform = "HarmonyOS"
                    } else {
                        harmony[k] = value[k]
                    }
                }

            }
        })
        if (harmony.length !== 0) {
            result.push(harmony)
        }
        setDownloadLinkHandle(result)
        setLinks(result)
        return () => { };
    }, []);

    const downloadButtonHandle = () => {
        let path = ""
        links.forEach((value, index, array) => {
            if (DownloadIcons[UADetails.os.name].platform === value.platform) {
                console.log(value)
                path = value.download_path
                return
            }
        })

        if (path !== "") {
            const link = document.createElement('a');
            link.href = path
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } else {
            message.info("开发小哥正在加班开发中,敬请期待")
        }
    }

    const otherDownloadButtonHandle = (platform) => {
        let path = ""
        links.forEach((value, index, array) => {
            if (platform === value.platform) {
                console.log(value)
                path = value.download_path
                return
            }
        })

        if (path !== "") {
            const link = document.createElement('a');
            link.href = path
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } else {
            message.info("开发小哥正在加班开发中,敬请期待")
        }
    }

    return (
        <div>
            <div style={{ display: "inline-block" }}>
                <div className="banner-button" onClick={downloadButtonHandle}>
                    {DownloadIcons[UADetails.os.name].icon}
                    <div className="banner-title">{UADetails.os.name}下载</div>
                </div>
            </div>

            <div className="banner-button-other">
                <div>其他平台</div>
                {Object.entries(DownloadIcons).map((key, index) => {
                    if (key[0] != UADetails.os.name) {
                        return (
                            <div key={index} onClick={(event) => {
                                otherDownloadButtonHandle(key[1].platform)
                            }}>
                                {key[1].icon}
                            </div>
                        )
                    }
                })}
            </div>
        </div>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        setDownloadLinkHandle: (downloadLinks) => {
            dispatch({
                type: "DOWNLOADLINKS",
                state: { downloadLinks },
            });
        },
    };
};
export default connect(null, mapDispatchToProps)(DownloadButton);