import React from "react";
import { Form, Input, Button, message } from "antd";
import "../../asset/css/branch.scss";
import { contactUs } from "../../api/api";
import validator from "email-validator";

const ContactUs = (props) => {
  const { TextArea } = Input;
  const [form] = Form.useForm();

  const sendAdvicesHandle = (e) => {
    console.log(e);
    if (e.email === undefined) {
      message.error("未输入邮箱");
      return;
    }
    var res = validator.validate(e.email);

    if (res !== true) {
      message.error("邮箱格式错误");
      return;
    }

    if (e.content === undefined) {
      message.error("未输入内容");
      return;
    }

    contactUs(e)
      .then((data) => {
        if (data.status === 0) {
          message.success("发送成功");
          console.log(form);
          form.resetFields();
        }
      })
      .catch((err) => {});
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <div className="contact-us">
      <div className="contact-us-title">
        <h1>联系我们</h1>
        <p>
          如果你对产品有任何疑问，或者想要对我们提出任何意见或建议，请联系我们
        </p>
      </div>
      <div className="contact-us-form">
        <Form
          name="contact-us-form"
          onFinish={sendAdvicesHandle}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          initialValues={{ remember: true }}
          form={form}
        >
          <p>请输入您的邮箱，我们会将结果回复到此邮箱</p>
          <Form.Item name="email">
            <Input placeholder="您的邮箱"></Input>
          </Form.Item>
          <p>请输入您想要发送给我们的内容</p>
          <Form.Item name="content">
            <TextArea
              showCount
              maxLength={200}
              placeholder="请输入内容"
              style={{ height: "164px" }}
            ></TextArea>
          </Form.Item>
          <Form.Item style={{ textAlign: "center" }}>
            <Button type="primary" htmlType="submit" className="contact-us-btn">
              发送
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default ContactUs;
