import React, { useState, useEffect } from "react";
import { Row, Col, Form, Input, Button, message } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import { userLogin, sendVerificationCode } from "../api/api";
import "../asset/css/login.scss";

const Login = (props) => {
  const { hideHandler, updateHandler, loginAction, registerAction } = props;
  const [form] = Form.useForm();
  const [verifyCodeTitle, setVerifyCodeTitle] = useState("发送验证码");
  const [canSubmit, setCanSubmit] = useState(false);
  var verifyCodeInterval
  var count = 60;


  const loginHandler = async (form) => {
    var email = form.email;
    var number = form.number;
    let data = {
      "email": email,
      "verifyCode": number
    };

    let response = await userLogin(data);
    if (response.status === 0) {
      updateHandler();
      hideHandler();
    } else {
      message.error(response.errmsg);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const sendVerifyCode = async () => {
    var email = form.getFieldsValue().email;
    if (email == undefined || email == "") {
      message.error("缺少邮箱")
    }

    let response = await sendVerificationCode({ "email": email })
    if (response.status === 0) {
      verifyCodeInterval = setInterval(() => {
        if (count === 0) {
          clearInterval(verifyCodeInterval);
          count = 60;
          setVerifyCodeTitle("发送验证码");
          setCanSubmit(false);
        } else {
          count--;
          setVerifyCodeTitle(count + "s后重新发送");
          setCanSubmit(true);
        }
      }, 1000);
    } else {
      message.error(response.errmsg)
    }
  }

  const hidenClick = () => {
    if (verifyCodeInterval) {
      clearInterval(verifyCodeInterval);
    }
    hideHandler()
  }

  return (
    <div className="login-panel">
      <Row justify="space-between" align="center">
        <Col>
          <h2> 登录 </h2>
        </Col>
        <Col onClick={hidenClick.bind(this)}>
          <CloseOutlined style={{ fontSize: "24px" }} />
        </Col>
      </Row>
      <Form
        form={form}
        name="login"
        onFinish={loginHandler}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        initialValues={{ remember: true }}
      >
        <Form.Item
          name="email"
          style={{ marginTop: "20px", marginBottom: "10px" }}
        >
          <Input type='email' placeholder="请输入邮箱账号" />
        </Form.Item>
        <div className="verification-code-menu">
          <Form.Item name="number" {...{
            wrapperCol: {
              span: 18,
              offset: 0,
            },
          }}>
            <Input maxLength="6" placeholder="请输入验证码" />
          </Form.Item>
          <Form.Item {...{
            wrapperCol: {
              span: 18,
              offset: 0,
            },
          }}>
            <Button disabled={canSubmit} onClick={sendVerifyCode}>{verifyCodeTitle}</Button>
          </Form.Item>
        </div>

        <div className="register-menu">
          <div>
            <p id="tips">账号或密码不正确</p>
          </div>
          <div>
            <a onClick={registerAction.bind(this)}>还未注册?</a>
          </div>
        </div>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="login-button"
            style={{
              background: "#FFCD31",
              borderRadius: "8px",
              borderColor: "#fff",
              height: "48px",
            }}
          >
            登录
          </Button>
        </Form.Item>
      </Form>
    </div >
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    hideHandler: (userInfo) => {
      dispatch({
        type: "HIDEMODAL",
        state: { userInfo },
      });
    },
    updateHandler: (userInfo) => {
      dispatch({
        type: "UPDATE_INFO",
        state: { userInfo },
      });
    },
    loginAction: () => {
      dispatch({
        type: "LOGINED",
      });
    },
    registerAction: () => {
      dispatch({
        type: "REGISTER",
      });
    },
  };
};

export default connect(null, mapDispatchToProps)(Login);
