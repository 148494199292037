import React, { useState, useEffect } from "react";
import "../../asset/css/branch.scss";
import { privacyInfo } from "../../api/api";

const Privacy = (props) => {
  const [dataSource, setDataSource] = useState({});
  useEffect(() => {
    // privacyInfo().then((data) => {
    //   setDataSource(data);
    // });
  }, []);

  return (
    <div className="contact-us contact-us-form ">
      <div>
        <div><h1>隐私政策</h1><p>{process.env.PROJECT_NAME} VPN 尊重并全力保护用户隐私。我们希望您了解我们收集的信息，不收集的信息以及我们如何收集、使用和存储信息。我们不收集您的活动日志，包括不记录浏览历史记录、流量数据、数据内容或 DNS 查询。我们也从不存储连接日志，这意味着您的 IP 地址、连接时间或会话持续时间都不会记录日志。</p></div>
        <div>
          <div>
            <h2>同意</h2>
            <p>我们非常注重用户隐私，因此制定隐私政策。请您仔细阅读这些隐私政策的内容，以协助本平台为您提供满意的服务。本政策内容涵盖本平台如何经由使用本平台而收集所得的个人资料。使用本网站服务，即表示您已同意接受本平台的隐私政策，并同意本平台按照以下政策收集个人资料及使用方法。</p>
          </div>
          <div>
            <h2>手机号 &amp; 邮箱</h2>
            <p>当您注册本平台时，您可以选择填写手机号或者邮箱，但是两者不是必须的，不填写也不会影响账号注册。我们非常尊重用户个人信息，不会为满足第三方营销目的而向其出售或出租您的任何信息。</p>
          </div>
          <div>
            <h2>年龄限制</h2>
            <p>该服务适用于18岁以上的成年人。如果您认为您的孩子已经向我们提供了信息，请立即告知我们。</p>
          </div>
          <div>
            <h2>信息安全</h2>
            <p>您也有责任保护及妥善存放个人资料，避免通过任何途径（包括电话以及电邮）向其他人分享或公开您在本平台的登录密码。必要时，我们会使用强大的加密算法来安全地保护所有公钥和私钥以及密码。采取了适当的安全措施来保护此信息，防止未经授权的访问，未经授权的更改，破坏或泄露数据。</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Privacy;
