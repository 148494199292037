import React, { useEffect, useState } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { getGoodsInfo } from "../../api/api";
import { message } from "antd";
import { orderGood, isLogin } from "../../api/api";
import TradeModalView from "../../components/trade_modal_view";
import "../../asset/css/login.scss";
import alipay from "../../asset/icon/alipay.svg";
import wechatpay from "../../asset/icon/wechatpay.svg";
import selected from "../../asset/icon/selected.svg";
import unselected from "../../asset/icon/unselected.svg";
import { connect } from "react-redux";
import Cookies from "js-cookie";
import { ApiFilled } from "@ant-design/icons";

const Tarded = (props) => {
  const PayType = {
    1: "BANKPAY",
    2: "ALIPAY",
    3: "WECHATPAY",
  };
  const { hideHandler } = props;
  const params = useLocation();
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const [dataSource, setDataSource] = useState([]);
  const [payButtonTitle, setPayButtonTitle] = useState("前往支付");
  const [visible, setVision] = useState(false);
  const [mchTradeCode, setMchTradeCode] = useState("");

  const [payMethod, setPayMethod] = useState([
    // {
    //   id: 1,
    //   title: "银行卡支付",
    //   select: unselected,
    //   icon: (
    //     <div className="trade-pay-method-item-right">
    //       <div
    //         className="iconfont wice-yinlian"
    //         style={{ lineHeight: "1" }}
    //       ></div>
    //       <div>
    //         <p
    //           style={{
    //             fontSize: "10px",
    //             marginBottom: "0",
    //             lineHeight: "10px",
    //           }}
    //         >
    //           Bank
    //         </p>
    //       </div>
    //     </div>
    //   ),
    // },
    {
      id: 2,
      title: "支付宝",
      select: selected,
      icon: (
        <div>
          <img src={alipay} alt={"alipay"} />
        </div>
      ),
    },
    // {
    //   id: 3,
    //   title: "微信",
    //   select: unselected,
    //   icon: (
    //     <div>
    //       <img src={wechatpay} alt={"alipay"} />
    //     </div>
    //   ),
    // },
  ]);
  const getUrlToken = (name, str) => {
    const reg = new RegExp(`(^|&)${name}=([^&]*)(&|$)`);
    const r = str.substr(1).match(reg);
    if (r != null) return decodeURIComponent(r[2]);
    return null;
  };
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [itemSelectedIndex, setItemSelectedIndex] = useState(-1);
  useEffect(() => {
    const session = getUrlToken("session", location.search);
    const name = getUrlToken("name", location.search);
    const userId = getUrlToken("userId", location.search);
    const goodsId = getUrlToken("goodsId", location.search);
    if (
      session !== undefined &&
      name !== undefined &&
      userId !== undefined &&
      session !== null &&
      name !== null &&
      userId !== null
    ) {
      Cookies.set("session", session);
      let userInfo = { name: name, user_id: userId };
      Cookies.set("user-info", JSON.stringify(userInfo));
      hideHandler(userInfo);
    }
    getGoodsInfo().then((goods) => {
      goods.forEach((good, index) => {
        if (params.state === null && goodsId === null) {
          if (good.id == 1) {
            good.isSelected = true;
            setPayButtonTitle(
              (good.amount * good.discount) / 100 +
                " " +
                good.amount_type +
                "  前往支付"
            );
            setItemSelectedIndex(index);
          } else {
            good.isSelected = false;
          }
        } else if (goodsId !== null) {
          if (good.id === goodsId) {
            good.isSelected = true;
            setPayButtonTitle(
              (good.amount * good.discount) / 100 +
                " " +
                good.amount_type +
                "  前往支付"
            );
            setItemSelectedIndex(index);
          } else {
            good.isSelected = false;
          }
        } else {
          if (good.id == params.state.id) {
            good.isSelected = true;
            setPayButtonTitle(
              (good.amount * good.discount) / 100 +
                " " +
                good.amount_type +
                "  前往支付"
            );
            setItemSelectedIndex(index);
          } else {
            good.isSelected = false;
          }
        }
      });
      setDataSource(goods);
    });
  }, []);

  const tradeCardItemHandle = (item, key) => {
    if (itemSelectedIndex > -1) {
      var oldItem = dataSource[itemSelectedIndex];
      oldItem.isSelected = false;
      dataSource.splice(itemSelectedIndex, 1, oldItem);
    }
    item.isSelected = true;
    dataSource.splice(key, 1, item);
    setItemSelectedIndex(key);
    setDataSource(dataSource);
    setPayButtonTitle(
      (item.amount * item.discount) / 100 +
        " " +
        item.amount_type +
        "  前往支付"
    );
  };

  const payMethodClickHandler = (item) => {
    if (selectedIndex > 0) {
      var oldItem = payMethod[selectedIndex - 1];
      oldItem.select = unselected;
      payMethod.splice(oldItem.id - 1, 1, oldItem);
    }
    item.select = selected;
    payMethod.splice(item.id - 1, 1, item);
    setSelectedIndex(item.id);
    setPayMethod(payMethod);
  };

  const goToPay = () => {
    if (itemSelectedIndex < 0) {
      message.error("未选择商品类型");
      return;
    }
    let w = window.open("", "_blank");
    isLogin()
      .then((res) => {
        console.log(res);
        orderGood({
          goodId: dataSource[itemSelectedIndex].goods_id,
          payType: "ALIPAY",
        })
          .then((res) => {
            w.location = res.result.payUrl;
            setMchTradeCode(res.result.mchTradeCode);
            setVision(true);
            console.log("trade =====" + res.result.mchTradeCode);
          })
          .catch((err) => {
            console.log(err);
            setVision(false);
          });
      })
      .catch((err) => {
        console.log(err);
        message.error("未登录");
      });
  };

  const didComplete = () => {
    console.log("didComplete");
    setVision(false);
    navigate("/");
  };

  const cancelTrade = () => {
    console.log("cancelTrade");
    setVision(false);
  };

  const keepTwoDecimal = (num) => {
    var result = parseFloat(num);
    if (isNaN(result)) {
      alert("传递参数错误，请检查！");
      return false;
    }
    result = Math.round(num * 100) / 100;
    return result;
  };

  const getRealCharge = (item) => {
    const charge = (item.amount * item.discount) / 100;
    return keepTwoDecimal(charge);
  };

  return (
    <div style={{ textAlign: "center", padding: "60px 20px 60px 20px" }}>
      <h1>选择套餐</h1>
      <p>对套餐内容的描述，尽可能引导用户付费，文本长度不限</p>
      <div className="trade-list">
        {dataSource.map((item, key) => (
          <div
            key={key}
            className="tarde-item"
            onClick={tradeCardItemHandle.bind(this, item, key)}
            style={item.isSelected ? { border: "4px solid #FFCD31" } : null}
          >
            {item.isSelected ? (
              <img className="trade-pay-item-select-icon" src={selected}></img>
            ) : null}
            <h3>{item.goods_info}</h3>
            <div id="charge">
              <h1>{getRealCharge(item)}</h1>
              <p>{item.amount_type}</p>
            </div>
            <div className="trade-discount">
              <div id="discount">
                <p>{item.discount * 100}% OFF</p>
              </div>
              <div>
                <h4 style={{ textDecoration: "line-through" }}>
                  {keepTwoDecimal(item.amount / 100)}
                </h4>
              </div>
              <div>
                <p>{item.goods_info}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
      <h1>选择支付方式</h1>
      <div className="trade-pay-method">
        {payMethod.map((item, key) => (
          <div
            key={key}
            className="trade-pay-method-item"
            // onClick={payMethodClickHandler.bind(this, item)}
            style={{ border: "4px solid #FFCD31" }}
          >
            <span>
              <img
                src={item.select}
                alt={item.title}
                style={{ marginRight: "5px" }}
              />
              {item.title}
            </span>
            {item.icon}
          </div>
        ))}
      </div>
      <div className="trade-pay-button" onClick={goToPay.bind(this)}>
        <a>
          <h3>{payButtonTitle}</h3>
        </a>
      </div>
      <TradeModalView
        visible={visible}
        mchTradeCode={mchTradeCode}
        didComplete={didComplete.bind(this)}
        cancelTrade={cancelTrade.bind(this)}
      ></TradeModalView>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    hideHandler: (userInfo) => {
      dispatch({
        type: "HIDEMODAL",
        state: { userInfo },
      });
    },
  };
};

export default connect(null, mapDispatchToProps)(Tarded);
