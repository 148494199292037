import React, { useEffect } from "react";
import { Anchor } from "antd";
import { connect } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

const { Link } = Anchor;
const DropdownMenu = (props) => {
  const { groups, onVision, setVision, loginOutHandle, showModalHandle } =
    props;

  useEffect(() => {}, []);
  const navigate = useNavigate();
  const location = useLocation();

  const linkClickHandler = (item) => {
    setVision(false);
    if (item.href === "#login") {
      showModalHandle();
    } else if (item.href === "#logout") {
      loginOutHandle();
    } else if (item.href === "#contact-us") {
      navigate("/contact-us");
    } else if (item.href === "#privacy") {
      navigate("/privacy");
    } else if (item.href === "#user-terms") {
      navigate("/user-terms");
    } else {
      navigate("/");
    }
  };

  if (onVision === true) {
    return (
      <div className="dropmenu-view" style={{}}>
        <Anchor affix={false}>
          {groups.map((items, k1) =>
            items.map((item, k2) => (
              <div
                key={k1 * 10 + k2}
                onClick={linkClickHandler.bind(this, item)}
              >
                <Link
                  key={k1 * 10 + k2}
                  title={item.title}
                  href={item.href}
                  className=".anchor-link-cus"
                ></Link>
              </div>
            ))
          )}
        </Anchor>
      </div>
    );
  } else {
    return <div></div>;
  }
};

const mapStateToProps = (state) => {
  return {
    isLogin: state.isLogin,
    userInfo: state.userInfo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loginOutHandle: () => {
      dispatch({
        type: "LOGOUT",
      });
    },
    showModalHandle: () => {
      dispatch({
        type: "SHOWMODAL",
        modalType: "LOGIN",
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DropdownMenu);
