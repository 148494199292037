import React, { useEffect, useState } from "react";
import { getGoodsInfo, orderGood, queryPayReults } from "../api/api";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { message, Modal } from "antd";
import QRCode from 'qrcode.react';
import { useUA } from 'use-ua-parser-js';

const Buy = (props) => {
  const [dataSource, setDataSource] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [orderStatus, setOrderStatus] = useState(false);
  const [queryCount, setQueryCount] = useState(0)
  const [payUrl, setPayUrl] = useState("_")
  const [orderId, setOrderId] = useState("")
  const { isLogin, showHandler } = props;
  const navigate = useNavigate();
  const UADetails = useUA();
  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    getGoodsInfo().then((response) => {
      if (response !== undefined) {
        setDataSource(response);
      }
    });
  }, []);

  const getParamValue = (paramName) => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(paramName);
  }

  const handleOk = () => {
    setIsModalOpen(false);
    let interval = setInterval(() => {
      console.log(orderStatus)
      if (queryCount === 30 || orderStatus === true) {
        clearInterval(interval)
      } else {
        var count = queryCount + 1
        setQueryCount(count)
        queryPayReults({ orderId: orderId }).then((value) => {
          if (value.status === 0) {
            window.location.href = "/wice.html";
            setOrderStatus(true)
          }
        })
      }
    }, 5000);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const orderGoods = async (item) => {
    if (isLogin == false) {
      showHandler();
      return;
    }
    var platform = UADetails.os.name.toLowerCase()
    let hide = messageApi.loading("正在创建订单")

    var value = await orderGood({ goodsId: item.goods_id, platform: platform, channel_id: getParamValue("channel_id") })
    setTimeout(hide, 100);
    if (value.errcode === 0) {
      setOrderId(value.data.orderId)
      if (UADetails.os.name === "iOS" || UADetails.os.name === "Android") {
        window.location.href = value.data.orderInfo
      } else {
        // setIsModalOpen(true)
        // debugger
        // setPayUrl(value.data.orderInfo)
        window.location.href = value.data.orderInfo
      }
    }
  };

  const keepTwoDecimal = (num) => {
    var result = parseFloat(num);
    if (isNaN(result)) {
      alert("传递参数错误，请检查！");
      return false;
    }
    result = Math.round(num * 100) / 100;
    return result;
  };

  const getRealCharge = (item) => {
    const charge = (item.amount * item.discount) / 100;
    return keepTwoDecimal(charge);
  };

  return (
    <div id="buy" className="package">
      <div style={{ textAlign: "center", marginBottom: "80px" }}>
        <h1>套餐&价格</h1>
      </div>
      <div className="" style={{ overflowX: "hidden" }}>
        <div className="package-scroll">
          {dataSource.map((item, key) => (
            <div key={key} className="package-item">
              <div className="package-content">
                <h3>{item.goods_info}</h3>
                <div id="charge">
                  <h1>{getRealCharge(item)}</h1>
                  <p>{item.amount_type}</p>
                </div>
                <div className="discount-container">
                  <div id="discount">
                    <p>{((1 - item.discount) * 100).toFixed(2)}% OFF</p>
                  </div>
                  <h4
                    style={{
                      textDecoration: "line-through",
                      margin: "0 14px 0 14px",
                    }}
                  >
                    {keepTwoDecimal(item.amount / 100)}
                  </h4>
                  <p>{item.goods_info}</p>
                </div>
              </div>

              <div
                id="buy-button"
                onClick={() => {
                  orderGoods(item);
                }}
              >
                <a>立即购买</a>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Modal title="扫码支付" className="custom-ant-modal"
        open={isModalOpen}
        onCancel={handleCancel}
        onOk={handleOk} cancelText="取消支付" okText="已支付">
        <QRCode value={payUrl}></QRCode>
      </Modal>
      {contextHolder}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isLogin: state.isLogin,
  };
};

const mapDispatchToProps = (action) => {
  return {
    showHandler: (goods) => {
      action({
        type: "SHOWMODAL",
        modalType: "LOGIN",
        model: goods,
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Buy);
