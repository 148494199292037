import React from "react";
import { connect } from "react-redux";
import "../asset/css/components.scss";
import Cookies from "js-cookie";

const UserMenu = (props) => {
  const { loginOutHandle } = props;
  return (
    <div className="user-menu">
      <div>
        <a
          style={{ color: "#ffffff" }}
          onClick={() => {
            loginOutHandle();
          }}
        >
          退出登录
        </a>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  console.log(state);
  return {};
};

const mapDispathchToProps = (dispath) => {
  return {
    loginOutHandle: () => {
      dispath({
        type: "LOGOUT",
      });
    },
  };
};

export default connect(mapStateToProps, mapDispathchToProps)(UserMenu);
