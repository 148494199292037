import http from "./https";
import validator from "email-validator";

const baseUrl = () => {
  if (process.env.NODE_ENV === "development") {
    return "";
  }
  return "";
};

const getGoodsInfo = () => {
  return new Promise((resolve, reject) => {
    http
      .get(baseUrl() + "/wice/web/getGoodsInfo")
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const userLogin = (data) => {
  return new Promise((resolve, reject) => {
    http
      .post(baseUrl() + "/wice/web/weblogin", data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const orderGood = (data) => {
  return new Promise((resolve, reject) => {
    http
      .post(baseUrl() + "/wice/web/orderGood", data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const sendVerificationCode = (data) => {
  return new Promise((resolve, reject) => {
    http.post(baseUrl() + "/wice/web/websendcode", data).then((response) => {
      resolve(response);
    })
      .catch((error) => {
        reject(error);
      });;
  });
};

const register = (data) => {
  let account = data.account;
  var res = validator.validate(account);
  if (res === true) {
    data.registerType = "EMAIL";
  } else {
    data.registerType = "MOBILE";
  }
  return new Promise((resolve, reject) => {
    http
      .post(baseUrl() + "/wice/web/webregister", data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const userterms = () => {
  return http.get(baseUrl() + "/wice/web/userterms");
};

const privacyInfo = () => {
  return new Promise((resolve, reject) => {
    http
      .get(baseUrl() + "/wice/web/privacyinfo")
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const contactUs = (data) => {
  return http.post(baseUrl() + "/wice/web/contactus", data);
};

const isLogin = (data) => {
  return new Promise((resolve, reject) => {
    http
      .get(baseUrl() + "/wice/web/isLogin", data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const queryPayReults = (data) => {
  return new Promise((resolve, reject) => {
    http
      .get(baseUrl() + "/wice/web/querypayresults", data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getusernames = (data) => {
  return new Promise((resolve, reject) => {
    http
      .get(baseUrl() + "/wice/web/getusername", data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getDownloadLinkUrl = (platform) => {
  return new Promise((resolve, reject) => {
    http
      .get(baseUrl() + "/wice/web/getDownloadLinks")
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export {
  getGoodsInfo,
  userLogin,
  orderGood,
  sendVerificationCode,
  register,
  userterms,
  privacyInfo,
  contactUs,
  queryPayReults,
  isLogin,
  getusernames,
  getDownloadLinkUrl
};
