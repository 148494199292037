import React, { useState, useEffect } from "react";
import { CloseOutlined } from "@ant-design/icons";
import { Form, Input, Button, message } from "antd";
import "../asset/css/login.scss";
import { register, sendVerificationCode } from "../api/api";
import { connect } from "react-redux";
import md5 from "js-md5";

const Register = (props) => {
  const { hideHandler, updateHandler, loginAction } = props;
  const [form] = Form.useForm();
  const [verifyCodeTitle, setVerifyCodeTitle] = useState("发送验证码");
  const [canSubmit, setCanSubmit] = useState(true);
  var count = 60;

  const registerHandler = (e) => {
    let formdata = form.getFieldsValue("register");

    let data = {
      email: formdata.account,
      verifyCode: formdata.verificationCode,
    };

    register(data)
      .then((res) => {
        console.log(res);
        if (res.status !== 0) {
          message.error(res.errmsg);
          return;
        }
        message.success("注册成功");
        updateHandler(res.result);
        hideHandler(res.result);
      })
      .catch((error) => {
        console.log(error);
        message.error(error.errmsg);
      });
  };

  const onFinishFailed = (e) => { };

  const goToLoginHandler = (e) => { };
  // 发送验证码
  const sendVerificationCodeHandle = (e) => {
    let formdata = form.getFieldsValue("register");
    if (formdata.account === undefined) {
      message.error("尚未输入账号");
      return;
    }
    if (canSubmit !== true) {
      return;
    }
    let data = { email: formdata.account };
    var verifyCodeInterval = setInterval(() => {
      if (count === 0) {
        clearInterval(verifyCodeInterval);
        count = 60;
        setVerifyCodeTitle("发送验证码");
        setCanSubmit(true);
      } else {
        count--;
        setVerifyCodeTitle(count + "s后重新发送");
        setCanSubmit(false);
      }
    }, 1000);
    sendVerificationCode(data)
      .then((res) => {
        message.success("发送验证码成功");
      })
      .catch((error) => {
        message.error("发送验证码错误");
      });
  };
  return (
    <div className="register-panel">
      <div className="register-panel-header">
        <h2>注册</h2>
        <CloseOutlined
          onClick={hideHandler.bind(this)}
          style={{ fontSize: "24px" }}
        ></CloseOutlined>
      </div>
      <div>
        <Form
          form={form}
          id="register-form"
          name="register"
          onFinish={registerHandler}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          initialValues={{ remember: true }}
        >
          <Form.Item name="account" style={{ marginBottom: "10px" }}>
            <Input maxLength="32" placeholder="请输入邮箱账号" />
          </Form.Item>

          <div className="verification-code-menu">
            <Form.Item
              name="verificationCode"
              style={{ marginBottom: "10px", width: "60%" }}
            >
              <Input maxLength="6" placeholder="请输入验证码" />
            </Form.Item>
            <div
              onClick={sendVerificationCodeHandle.bind(this)}
              style={{ textAlign: "center", width: "40%" }}
            >
              <a>{verifyCodeTitle}</a>
            </div>
          </div>

          <div className="register-menu">
            <div>
              <p id="tips">账号或密码不正确</p>
            </div>
            <div>
              <a onClick={loginAction.bind(this)}>去登录</a>
            </div>
          </div>
          <Form.Item name="register">
            <Button
              type="primary"
              htmlType="submit"
              className="register-button"
              style={{
                background: "#FFCD31",
                borderRadius: "8px",
                borderColor: "#fff",
                height: "40px",
              }}
            >
              注册
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    hideHandler: (userInfo) => {
      dispatch({
        type: "HIDEMODAL",
        state: { userInfo },
      });
    },
    updateHandler: (userInfo) => {
      dispatch({
        type: "UPDATE_INFO",
        state: { userInfo },
      });
    },
    loginAction: () => {
      dispatch({
        type: "LOGIN",
      });
    },
  };
};

export default connect(null, mapDispatchToProps)(Register);
